import { RentalApplicationErrorMessages, RentalApplicationErrorCode } from '~/types/RentalApplication';
import { useLocalization } from './useLocalization';

export const useErrorMessage = () => {
  const { t } = useLocalization();

  const getErrorMessage = (code?: RentalApplicationErrorCode) => {
    if (code === undefined) {
      return '';
    }

    return t(RentalApplicationErrorMessages[code]);
  };

  return { getErrorMessage };
};
